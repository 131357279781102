export default function SpotlightIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12a3 3 0 116 0c0 1.5-1 2.5-1 2.5h-4s-1-1-1-2.5z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 2a7 7 0 00-7 7c0 3.5 2.5 6.5 2.5 6.5h9s2.5-3 2.5-6.5a7 7 0 00-7-7z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10 22h4m-4-2h4"
        />
      </svg>
    </>
  );
} 