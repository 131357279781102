import { SpotlightItem } from '../models/spotlight';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useFetch } from './fetch.hook';

const Routes = {
  spotlightItems: (merchantId: number) => `/merchants/${merchantId}/spotlight-items`,
  spotlightItem: (merchantId: number, itemId: number) => `/merchants/${merchantId}/spotlight-items/${itemId}`,
};

// Fetch spotlight items
export const useSpotlightItemsQuery = (merchantId: number) => {
  const { fetchJsonFunc } = useFetch();
  return useQuery<SpotlightItem[]>({
    queryKey: ['spotlightItems', merchantId],
    queryFn: () => fetchJsonFunc(Routes.spotlightItems(merchantId))
  });
};

// Create a new spotlight item
export const useCreateSpotlightItemMutation = (
  merchantId: number,
  onSuccess: () => void,
  onError: (error: any) => void
) => {
  const { fetchJsonFunc } = useFetch();
  return useMutation({
    mutationFn: (newItem: Partial<SpotlightItem>) => fetchJsonFunc(Routes.spotlightItems(merchantId), {
      method: 'POST',
      body: JSON.stringify(newItem),
    }),
    onSuccess,
    onError,
  });
};

// Update a spotlight item
export const useUpdateSpotlightItemMutation = (
  merchantId: number,
  itemId: number,
  onSuccess: () => void,
  onError: (error: any) => void
) => {
  const { fetchJsonFunc } = useFetch();
  return useMutation({
    mutationFn: (updatedItem: Partial<SpotlightItem>) => fetchJsonFunc(Routes.spotlightItem(merchantId, itemId), {
      method: 'PUT',
      body: JSON.stringify(updatedItem),
    }),
    onSuccess,
    onError,
  });
};

// Delete a spotlight item
export const useDeleteSpotlightItemMutation = (
  merchantId: number,
  config: { onSuccess: () => void, onError: (error: any) => void }
) => {
  const { executeFetchFunc } = useFetch();
  return useMutation({
    mutationFn: (itemId: number) => executeFetchFunc(Routes.spotlightItem(merchantId, itemId), {
      method: 'DELETE',
    }),
    ...config,
  });
}; 

