import { useState } from 'react';
import Table, { TableColumn } from '../../../common/library/Table';
import { Modal } from '../../../common/library/Modal';
import Button from '../../../common/library/Button';
import { PlusIcon } from '@heroicons/react/outline';
import Input from '../../../common/library/Input';
import useAuthContext from '../../../hooks/auth-context.hook';
import useMerchantInvites from '../../../hooks/merchant-invites.hook';
import { MerchantUserInvite } from '../../../models/invites';
import { toast } from 'react-toastify';

export default function MerchantUsersInvites() {
  const { merchant } = useAuthContext().getMerchantAndUser();
  const { useGetMerchantInvitesQuery, useInviteUserToMerchantMutation, useDeleteMerchantInviteMutation } =
    useMerchantInvites();

  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const { data: invites, isLoading, refetch: refetchInvites } = useGetMerchantInvitesQuery(merchant.id);
  const { mutate: inviteUser, status: inviteStatus } = useInviteUserToMerchantMutation(() => {
    toast('Successfully sent invite', { type: 'success' });
    setCreateOpen(false);
    refetchInvites();
  }, () => {
    toast('An error occurred sending invite', { type: 'error' });
  });

  const { mutate: deleteInvite, status: deleteStatus } = useDeleteMerchantInviteMutation(
    merchant.id,
    () => {
      toast('Successfully removed invite', { type: 'success' });
      refetchInvites();
    },
    () => {
      toast('An error occurred removing invite', { type: 'error' });
    }
  );

  const columns: TableColumn<MerchantUserInvite>[] = [
    ...(invites && invites.some(invite => invite.user) ? [{
      label: 'Email',
      format: (val: MerchantUserInvite) => <>{val.user && val.user.email}</>,
    }] : []),
    {
      label: 'Status',
      format: (val: MerchantUserInvite) => <>{val.status.name}</>,
    },
    {
      label: 'Sent On',
      format: (val: MerchantUserInvite) => <>{new Date(val.createdDate).toLocaleDateString()}</>,
    },
    {
      label: 'Actions',
      format: (val: MerchantUserInvite) => (
        <Button
          label="Remove"
          onClick={() => deleteInvite(val.id)}
          loading={deleteStatus === 'pending'}
        />
      ),
    },
  ];

  return (
    <>
      <Modal
        open={createOpen}
        onClose={() => {
          setCreateOpen(false);
        }}
        buttonLoading={inviteStatus === 'pending'}
        explicitCloseButton
        onButtonClick={() =>
          inviteUser({ merchantId: merchant.id, email })
        }
        buttonLabel="Invite User"
        title="Invite User to Join Your Merchant Space"
      >
        <>
          <div className="w-[360px]">
            <Input
              value={email}
              label="Email"
              onChange={(val) => setEmail(val)}
            />
          </div>
        </>
      </Modal>

      <div className="flex justify-between px-2 md:px-0 w-full ">
        <div></div>
        <div className="float-right">
          <Button
            icon={<PlusIcon height={16} width={16} />}
            label="Invite User"
            onClick={() => setCreateOpen(true)}
          />
        </div>
      </div>
      <div>
        <div className="w-full">
          <Table
            loading={isLoading}
            columns={columns}
            data={invites ? invites : []}
          />
        </div>
      </div>
    </>
  );
}
