import { useMetrics } from '../../hooks/metrics.hook';
import { MetricType } from './type';
import { Card } from "../components/ui/card";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart
} from "recharts";

interface Props {
  metricType: MetricType;
  fromDate: Date;
  toDate: Date;
  primaryColor: string;
}

export function SystemMetricView({ primaryColor, metricType, fromDate, toDate }: Props) {

  const meta = () => {
    switch (metricType) {
      case ('MERCHANT_CREATED'): {
        return { title: 'Merchants created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      case ('USER_CREATED'): {
        return { title: 'Users created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      default: {
        return { title: 'No metric available', param: '' };
      }
    }
  };

  const { useSystemMetrics } = useMetrics();
  const { data, isSuccess, isLoading } = useSystemMetrics(
    metricType,
    fromDate,
    toDate,
  );

  const gradientId = `colorGradient-${metricType}`;

  return (
    <>
      <div className="font-bold mb-4">{meta().title}</div>
      {!isLoading && (
        <Card className="p-4">
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={isSuccess ? data.timeseries : []}
              margin={{
                top: 10,
                right: 10,
                bottom: 20,
                left: 10,
              }}
            >
              <defs>
                <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={primaryColor} stopOpacity={0.3} />
                  <stop offset="95%" stopColor={primaryColor} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                stroke="#f0f0f0"
              />
              <XAxis
                dataKey="date"
                tick={{ fontSize: 12, fill: '#666' }}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                tick={{ fontSize: 12, fill: '#666' }}
                tickLine={false}
                axisLine={false}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: 'white',
                  border: '1px solid #f0f0f0',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
              />
              <Area
                type="monotone"
                dataKey="count"
                stroke={primaryColor}
                strokeWidth={2}
                fill={`url(#${gradientId})`}
                dot={false}
                activeDot={{
                  r: 6,
                  stroke: 'white',
                  strokeWidth: 2,
                  fill: primaryColor
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Card>
      )}
    </>
  );
}