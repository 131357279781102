import { useFetch } from './fetch.hook';
import {
  Coupon, CouponClaim, CouponRedemptionMethod,
  CouponRequestDTO,
  CouponStatus, CouponType,
  RedeemedCouponResponse,
} from '../models/coupon';
import { Page } from '../types';
import { useMutation, useQuery } from '@tanstack/react-query';

const Routes = {
  coupons: (merchantId: number) =>
    `/merchants/${merchantId}/coupons`,
  couponsRedemptionMethods: (merchantId: number) =>
    `/merchants/${merchantId}/coupons/redemption-methods`,
  couponScan: (merchantId: number) =>
    `/merchants/${merchantId}/coupons/scan`,
  coupon: (merchantId: number, couponId: number) =>
    `/merchants/${merchantId}/coupons/${couponId}`,
  archiveCoupon: (merchantId: number, couponId: number) =>
    `/merchants/${merchantId}/coupons/${couponId}/archive`,
  couponClaims: (merchantId: number, couponId: number) =>
    `/merchants/${merchantId}/coupons/${couponId}/claims`,
};

export default function useCoupons() {
  const { fetchJsonFunc, executeFetchFunc } = useFetch();

  const useMerchantCouponsQuery =
    (
      merchantId: number,
      status: CouponStatus = 'AVAILABLE',
      page: number = 0,
      pageSize: number = 10,
      initialData?: Page<Coupon>,
      type?: CouponType
    ) => {
      return useQuery({
        queryKey: ['merchantCoupons', page, pageSize, status, merchantId, type],
        queryFn: () => fetchJsonFunc<Page<Coupon>>(
          `${Routes.coupons(
            merchantId,
          )}?status=${status}&page=${page}&pageSize=${pageSize}${type ? `&type=${type}` : ''}`),
        initialData,
      });
    };

  const useMerchantSingleCouponQuery =
    (merchantId: number, couponId: number) => {
      return useQuery({
        queryKey: ['merchantCoupon', merchantId, couponId],
        queryFn: () => fetchJsonFunc<Coupon>(
          `${Routes.coupon(merchantId, couponId)}`),
      });
    };

  const useCreateCouponMutation =
    (
      merchantId: number,
      onSuccess: () => void,
      onError: (error: any) => void,
    ) => {
      return useMutation({
        mutationFn: (request: CouponRequestDTO) => fetchJsonFunc<Coupon[]>(
          Routes.coupons(merchantId),
          {
            method: 'POST',
            body: JSON.stringify(request),
          },
        ),
        onSuccess,
        onError,
      });
    };

  const useArchiveCouponMutation =
    (merchantId: number, onSuccess: () => void, onError: (error: any) => void) => {
      return useMutation({
        mutationFn: (couponId: number) => executeFetchFunc(
          Routes.archiveCoupon(merchantId, couponId),
          {
            method: 'PUT',
          },
        ),
        onSuccess,
        onError
      });
    };

  const useDeleteCouponMutation =
    (merchantId: number, onSuccess: () => void, onError: () => void) => {
      return useMutation({
        mutationFn: (couponId: number) => executeFetchFunc(
          Routes.coupon(merchantId, couponId),
          {
            method: 'DELETE',
          },
        ),
        onError,
        onSuccess,
      });
    };

  const useCouponRedemptionMethodsQuery =
    (
      merchantId: number,
    ) => {
      return useQuery({
        queryKey: ['merchantCouponRedemptionMethods', merchantId],
        queryFn: () => fetchJsonFunc<CouponRedemptionMethod[]>(
          Routes.couponsRedemptionMethods(merchantId)),
        initialData: [],
      });
    };

  const useScanCouponMutation =
    (onError: (error: any) => void) => {
      return useMutation({
        mutationFn: (req: {
          merchantId: number,
          userCouponId: number
        }) => fetchJsonFunc<RedeemedCouponResponse>(
          Routes.couponScan(req.merchantId), {
            method: 'POST',
            body: JSON.stringify({ userCouponId: req.userCouponId }),
          }),
        onError,
      });
    };

  const useCouponClaimsQuery = (merchantId: number, couponId: number, page: number = 0, pageSize: number = 10) => {
    return useQuery({
      queryKey: ['couponClaims', merchantId, couponId, page, pageSize],
      queryFn: () => fetchJsonFunc<Page<CouponClaim>>(
        `${Routes.couponClaims(merchantId, couponId)}?page=${page}&pageSize=${pageSize}`
      ),
    });
  };

  return {
    useMerchantCouponsQuery,
    useMerchantSingleCouponQuery,
    useCreateCouponMutation,
    useArchiveCouponMutation,
    useDeleteCouponMutation,
    useCouponRedemptionMethodsQuery,
    useScanCouponMutation,
    useCouponClaimsQuery,
  };
}
