import React, { useState, useCallback, useRef } from 'react';
import useAuthContext from '../../../hooks/auth-context.hook';
import { useCreateSpotlightItemMutation } from '../../../hooks/spotlight.hook';
import Button from '../../../common/library/Button';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import ImagePicker from '../../../common/library/ImagePicker';
import useContent from '../../../hooks/content.hook';
import Input from '../../../common/library/Input';

export default function CreateSpotlightItemPage() {
  const { merchant } = useAuthContext().getMerchantAndUser();
  const navigate = useNavigate();
  const { mutate, status } = useCreateSpotlightItemMutation(merchant.id, () => {
    // Handle success
    navigate('/dashboard/spotlight');
    toast.success('Spotlight item created successfully!');
  }, (error) => {
    // Handle error
    toast.error('An error occurred while creating the spotlight item.');
  });

  const { useUploadMerchantMediaMutation } = useContent();
  const uploadFile = useUploadMerchantMediaMutation(
    merchant.id,
    (res: { url: string }) => {
      executeCreateSpotlightItem(res.url);
    },
    () => {
      toast.error('An error occurred uploading the image.');
    }
  );

  const uploadUrlFile = useUploadMerchantMediaMutation(
    merchant.id,
    (res: { url: string }) => {
      setSpotlightData({ ...spotlightData, url: res.url });
      toast.success('File uploaded successfully!');
    },
    () => {
      toast.error('An error occurred uploading the file.');
    }
  );

  const [spotlightData, setSpotlightData] = useState({
    title: '',
    url: '',
    imageUrl: '',
  });

  const [file, setFile] = useState<File | undefined>();
  const urlFileInputRef = useRef<HTMLInputElement>(null);

  const handleUrlFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    const file = files[0];
    try {
      uploadUrlFile.mutate(file);
    } catch (error) {
      toast.error("Failed to upload file");
      console.error(error);
    }
    
    // Reset the input value so the same file can be selected again
    e.target.value = '';
  };

  const executeCreateSpotlightItem = useCallback((imageUrl?: string) => {
    mutate({
      ...spotlightData,
      imageUrl: imageUrl || spotlightData.imageUrl,
    });
  }, [mutate, spotlightData]);

  const handleSubmit = () => {
    if (!spotlightData.imageUrl && !file) {
      toast.error('An image is required for the spotlight item.');
      return;
    }
    if (file) {
      uploadFile.mutate(file);
    } else {
      executeCreateSpotlightItem();
    }
  };

  const handleTitleChange = (title: string) => {
    // Limit title to 120 characters
    if (title.length <= 120) {
      setSpotlightData({ ...spotlightData, title });
    }
  };

  return (
    <>
      <div className="flex items-center mb-4">
        <h1
          className="font-bold text-xl underline cursor-pointer text-[#fb3310]"
          onClick={() => navigate('/dashboard/spotlight')}
        >
          Spotlight Items
        </h1>
        <ChevronRightIcon className="h-5 w-5 text-gray-500 mx-1" />
        <h1 className="font-bold text-xl">Create Spotlight Item</h1>
      </div>

      <div className="max-w-4xl">
        <div className="w-full lg:w-[550px]">
          <div className="w-full py-3">
            <div className="flex items-center justify-between">
              <div className="flex-grow">
                <Input
                  label="Title (Optional)"
                  required={false}
                  onChange={handleTitleChange}
                  value={spotlightData.title}
                />
              </div>
              <div className="ml-2 mt-6 text-sm text-gray-500">
                {spotlightData.title.length}/120
              </div>
            </div>
          </div>
          <div className="w-full py-3">
            <div className="flex items-center">
              <div className="flex-grow">
                <Input
                  label="URL (Optional)"
                  required={false}
                  onChange={(url) => setSpotlightData({ ...spotlightData, url })}
                  value={spotlightData.url}
                />
              </div>
              <div className="ml-2 mt-6">
                <input
                  type="file"
                  ref={urlFileInputRef}
                  onChange={handleUrlFileUpload}
                  className="hidden"
                />
                <Button
                  label="Upload File"
                  onClick={() => urlFileInputRef.current?.click()}
                  className="py-1 px-2 text-sm"
                  disabled={uploadUrlFile.status === 'pending'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-3">
          <div className="font-bold mb-2">
            Spotlight Image {"(JPEG or PNG - 16:9 Aspect Ratio Suggested)"}
            <span className="text-red-500 ml-1">*</span>
          </div>
          <div className="text-sm text-gray-600 mb-4">
            Upload an image for your spotlight item{" "}
            <span className="text-red-500">
              (required)
            </span>
          </div>

          <ImagePicker
            onComplete={(imageUrl, file) => {
              setSpotlightData({ ...spotlightData, imageUrl });
              setFile(file);
            }}
            imageUrl={spotlightData.imageUrl}
            onRemove={() => {
              setSpotlightData({ ...spotlightData, imageUrl: '' });
              setFile(undefined);
            }}
          />
        </div>
        <Button
          className="mt-4"
          label="Create Spotlight Item"
          onClick={handleSubmit}
          disabled={status === 'pending'}
        />
      </div>
    </>
  );
} 