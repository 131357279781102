import { useFetch } from './fetch.hook';
import { DynamoRecords } from '../types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QbonDevice } from '../models/devices';

const Routes = {
  devices: () =>
    `/devices`,
  merchantDevices: (merchantId: number) => `/merchants/${merchantId}/devices`,
  merchantDevice: (merchantId: number, deviceId: string) => `/merchants/${merchantId}/devices/${deviceId}`,
  device: (deviceId: string) => `/devices/${deviceId}`,
};

export const useMerchantDeviceQuery = (deviceId: string) => {
  const { fetchJsonFunc } = useFetch();
  return useQuery({
    queryKey: ['merchantDevice', deviceId],
    queryFn: () => fetchJsonFunc<QbonDevice>(Routes.device(deviceId)),
    enabled: !!deviceId,
  });
};

export const useUpdateMerchantDeviceMutation = (onSuccess?: () => void, onError?: () => void) => {
  const { executeFetchFunc } = useFetch();
  return useMutation({
    mutationFn: (device: QbonDevice & { merchantId: number }) => executeFetchFunc(Routes.merchantDevice(device.merchantId, device.uuid), { method: 'PUT', body: JSON.stringify(device) }),
    onSuccess: onSuccess,
    onError: onError,
  });
};

export default function useDevices() {
  const { fetchJsonFunc, executeFetchFunc } = useFetch();

  const useMerchantDevicesQuery =
    (
      merchantId: number,
    ) => {
      return useQuery({
        queryKey: ['merchantDevices', merchantId],
        queryFn: () => fetchJsonFunc<DynamoRecords<QbonDevice>>(
          Routes.merchantDevices(
            merchantId,
          )),
      });
    };

  const useAdminDevicesQuery =
    () => {
      return useQuery({
        queryKey: ['adminDevices'],
        queryFn: () => fetchJsonFunc<DynamoRecords<QbonDevice>>(
          Routes.devices()),
      });
    };

  const useAssignMerchantDeviceMutation =
    (
      merchantId: number,
      onSuccess: () => void,
      onError: (error: any) => void,
    ) => {
      return useMutation({
        mutationFn: ({ deviceId }: { deviceId: string }) => executeFetchFunc(
          Routes.merchantDevices(merchantId),
          {
            method: 'POST',
            body: JSON.stringify({
              deviceId,
            }),
          },
        ),
        onSuccess,
        onError,
      });
    };

  const useUnassignMerchantDevice =
    (
      merchantId: number,
      onSuccess: () => void,
      onError: (error: any) => void,
    ) => {
      return useMutation({
        mutationFn: ({ deviceId }: { deviceId: string }) => executeFetchFunc(
          Routes.merchantDevice(merchantId, deviceId),
          {
            method: 'DELETE',
          },
        ),
        onSuccess,
        onError,
      });
    };

  const useAdminGenerateDevicesMutation =
    (
      onSuccess: () => void,
      onError: (error: any) => void,
    ) => {
      return useMutation({
        mutationFn: (req: { count: number }) => fetchJsonFunc<QbonDevice[]>(
          Routes.devices(),
          {
            method: 'POST',
            body: JSON.stringify(req),
          },
        ),
        onError,
        onSuccess,
      });
    };

  return {
    useAdminDevicesQuery,
    useMerchantDevicesQuery,
    useAssignMerchantDeviceMutation,
    useUnassignMerchantDevice,
    useAdminGenerateDevicesMutation,
  };
}
