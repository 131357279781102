import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSpotlightItemsQuery, useDeleteSpotlightItemMutation } from '../../../hooks/spotlight.hook';
import useAuthContext from '../../../hooks/auth-context.hook';
import Button from '../../../common/library/Button';
import { toast } from 'react-toastify';
import { ChevronRightIcon, ExternalLinkIcon, TrashIcon } from '@heroicons/react/outline';

export default function SpotlightItemPage() {
  const { merchant } = useAuthContext().getMerchantAndUser();
  const { itemId } = useParams<{ itemId: string }>();
  const { data: spotlightItems, isLoading } = useSpotlightItemsQuery(merchant.id);
  const navigate = useNavigate();

  const { mutate, status } = useDeleteSpotlightItemMutation(
    merchant.id,
    {
      onSuccess: () => {
        toast.success('Spotlight item deleted successfully');
        navigate('/dashboard/spotlight');
      },
      onError: (error) => {
        toast.error('Failed to delete spotlight item');
      },
    }
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-gray-200 h-12 w-12"></div>
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 rounded"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const spotlightItem = spotlightItems?.find(item => item.id === Number(itemId));

  if (!spotlightItem) {
    return (
      <div className="flex flex-col justify-center items-center h-64 bg-gray-50 rounded-lg border border-gray-200">
        <svg className="w-16 h-16 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <p className="mt-4 text-lg font-medium text-gray-700">No spotlight item found</p>
        <Button 
          label="Return to Spotlight Items" 
          onClick={() => navigate('/dashboard/spotlight')}
          className="mt-4"
        />
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center mb-6">
        <h1
          className="font-bold text-xl underline cursor-pointer text-[#fb3310] hover:text-[#d92800] transition-colors"
          onClick={() => navigate('/dashboard/spotlight')}
        >
          Spotlight Items
        </h1>
        <ChevronRightIcon className="h-5 w-5 text-gray-500 mx-1" />
        <h1 className="font-bold text-xl">Spotlight Item Details</h1>
      </div>

      <div className="w-full max-w-2xl bg-white p-6 rounded-lg shadow-md border border-gray-100">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-1/2">
            {spotlightItem.imageUrl ? (
              <div className="relative group overflow-hidden rounded-lg shadow-md">
                <img 
                  src={spotlightItem.imageUrl} 
                  alt={spotlightItem.title ?? 'Spotlight Item'} 
                  className="w-full h-auto object-cover rounded-lg transition-transform duration-300 group-hover:scale-105" 
                />
              </div>
            ) : (
              <div className="flex items-center justify-center h-64 bg-gray-100 rounded-lg border border-dashed border-gray-300">
                <p className="text-center text-gray-500">No image available</p>
              </div>
            )}
          </div>
          
          <div className="md:w-1/2 flex flex-col">
            <h2 className="font-bold text-2xl mb-4 text-gray-800">
              {spotlightItem.title || 'Untitled Spotlight Item'}
            </h2>
            
            <div className="mb-6">
              <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-2">URL</h3>
              {spotlightItem.url ? (
                <a 
                  href={spotlightItem.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 flex items-center gap-1 break-all"
                >
                  {spotlightItem.url}
                  <ExternalLinkIcon className="h-4 w-4 inline-block flex-shrink-0" />
                </a>
              ) : (
                <p className="text-gray-600">No URL available</p>
              )}
            </div>
            
            <div className="mt-auto">
              <div className="flex justify-end items-center">
                <Button
                  label="Delete Item"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this spotlight item?')) {
                      mutate(spotlightItem.id);
                    }
                  }}
                  disabled={status === 'pending'}
                  className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-md flex items-center gap-2"
                >
                  <TrashIcon className="h-4 w-4" />
                  {status === 'pending' ? 'Deleting...' : 'Delete Item'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 