import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicForm, { FormField } from "../../common/library/BasicForm";
import { Merchant, MerchantRequestDTO } from "../../models/merchant";
import { buildServerPhoneNumber, buildVisualPhoneNumber } from "../../util";
import { Divider, Step, StepLabel, Stepper } from "@mui/material";
import Button from "../../common/library/Button";
import { GeoLocation } from "../../hooks/google-maps.hook";
import Input from "../../common/library/Input";
import AutocompleteAddress from "../../common/AutocompleteAddress";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import useMerchants from "../../hooks/merchants.hook";
import { KeyIcon, XCircleIcon } from "@heroicons/react/outline";
import useAuthContext from "../../hooks/auth-context.hook";
import GenerateGoogleReviewLink from "../merchant-dashboard/merchant-profile/GenerateGoogleReviewLink";
import InfoBox from "../../common/InfoBox";
import { toast } from "react-toastify";

const defaultmerchantForm = {
  name: "",
  description: "",
  coordinates: undefined,
  email: "",
  phoneNumber: "",
  address: undefined,
  licenseCode: "",
  websiteUrl: "",
  instagramHandle: "",
  facebookHandle: "",
  xHandle: "",
  tiktokHandle: "",
};

export default function CreateMerchantPage() {
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const { user } = authContext.getUser();
  const { useCreateMerchantMutation, useMerchantIndustriesQuery } =
    useMerchants();
  const { data: industries } = useMerchantIndustriesQuery();
  const createMerchant = useCreateMerchantMutation(
    user.id,
    (merchant: Merchant) => {
      setmerchantForm(defaultmerchantForm);
      setStep(0);
      authContext.setMerchant(merchant);
      toast("Merchant has been created!", { type: "success" });
    },
    (error) => toast(error.message[0], { type: "error" })
  );

  const [reviewOpen, setReviewOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [merchantForm, setmerchantForm] =
    useState<MerchantRequestDTO>(defaultmerchantForm);
  const [merchantLocation, setMerchantLocation] = useState<GeoLocation>({
    placeId: "",
    addressline: "",
    city: "",
    postal_code: "",
    coordinates: { latitude: 0, longitude: 0 },
    country: "",
    county: "",
    state: "",
  });

  const createmerchant = useCallback(() => {
    if (!merchantForm.name || merchantForm.name === "") {
      toast("Please set a business name", { type: "error" });
      return;
    }

    if (!merchantForm.industry || merchantForm.industry === "") {
      toast("Please pick an industry that best describes your business", {
        type: "error",
      });
      return;
    }

    if (!merchantForm.description || merchantForm.description === "") {
      toast("Please provide a description of your business", { type: "error" });
      return;
    }

    if (merchantLocation.addressline === "" || merchantLocation.city === "") {
      toast(
        "Please input an address, if you are an e-commerce merchant use your business mailing address",
        { type: "error" }
      );
      return;
    }

    createMerchant.mutate({
      ...merchantForm,
      email: merchantForm.email !== "" ? merchantForm.email : undefined,
      description: merchantForm.description,
      facebookHandle: merchantForm.facebookHandle,
      xHandle: merchantForm.xHandle,
      instagramHandle: merchantForm.instagramHandle,
      tiktokHandle: merchantForm.tiktokHandle,
      websiteUrl: merchantForm.websiteUrl,
      coordinates: {
        type: "Point",
        coordinates: [
          merchantLocation.coordinates.latitude,
          merchantLocation.coordinates.longitude,
        ],
      },
      phoneNumber:
        merchantForm.phoneNumber && merchantForm.phoneNumber !== ""
          ? buildServerPhoneNumber(merchantForm.phoneNumber)
          : undefined,
      address: {
        addressLine1: merchantLocation.addressline,
        city: merchantLocation.city,
        country: merchantLocation.country,
        county: merchantLocation.county,
        postalCode: merchantLocation.postal_code,
        state: merchantLocation.state,
        addressLine2: "",
      },
      licenseCode: merchantForm.licenseCode,
    });
  }, [createMerchant, merchantLocation, merchantForm]);

  const fields: FormField[] = [
    {
      label: "Business Name",
      value: merchantForm.name,
      onValueChange: (name) => setmerchantForm({ ...merchantForm, name }),
      required: true,
    },
    {
      label: "Industry",
      value: merchantForm.industry,
      combobox: true,
      options: industries,
      onValueChange: (industry) =>
        setmerchantForm({ ...merchantForm, industry }),
      required: true,
    },
    {
      label: "Description",
      onValueChange: (description) =>
        setmerchantForm({ ...merchantForm, description }),
      value: merchantForm.description,
      required: true,
    },
    {
      label: "Website URL",
      onValueChange: (websiteUrl) =>
        setmerchantForm({ ...merchantForm, websiteUrl }),
      value: merchantForm.websiteUrl,
      required: false,
    },
    {
      label: "Email",
      value: merchantForm.email,
      onValueChange: (email) => setmerchantForm({ ...merchantForm, email }),
    },
    {
      label: "Phone Number",
      value: buildVisualPhoneNumber(merchantForm.phoneNumber!),
      onValueChange: (phoneNumber) =>
        setmerchantForm({
          ...merchantForm,
          phoneNumber: buildVisualPhoneNumber(phoneNumber),
        }),
    },
    {
      label: "License Code",
      value: merchantForm.licenseCode,
      onValueChange: (licenseCode) =>
        setmerchantForm({ ...merchantForm, licenseCode }),
      required: false,
    },
  ];

  const socialMediaFields: FormField[] = [
    {
      label: "Facebook Page Name",
      value: merchantForm.facebookHandle,
      onValueChange: (facebookHandle) =>
        setmerchantForm({ ...merchantForm, facebookHandle }),
      required: false,
    },
    {
      label: "Instagram Username",
      value: merchantForm.instagramHandle,
      onValueChange: (instagramHandle) =>
        setmerchantForm({ ...merchantForm, instagramHandle }),
      required: false,
    },
    {
      label: "TikTok Username",
      onValueChange: (tiktokHandle) =>
        setmerchantForm({ ...merchantForm, tiktokHandle }),
      value: merchantForm.tiktokHandle,
      required: false,
    },
    {
      label: "X Username",
      onValueChange: (xHandle) => setmerchantForm({ ...merchantForm, xHandle }),
      value: merchantForm.xHandle,
      required: false,
    },
  ];

  return (
    <div className="w-full min-h-screen md:p-8 bg-white">
      <GenerateGoogleReviewLink
        open={reviewOpen}
        onFinished={(link: string) => {
          setReviewOpen(false);
          setmerchantForm({ ...merchantForm, googleReviewUrl: link });
        }}
        onClose={() => setReviewOpen(false)}
      />
      <div className="flex items-center mb-6 pt-3 pl-3 md:pl-0 md:pt-0 justify-between">
        <h1 className="font-bold text-xl">Merchant Setup</h1>
        <div className={"px-3"}>
          <Button
            label={"Exit"}
            icon={<XCircleIcon />}
            onClick={() => navigate("merchants")}
            alwaysShowLabel
            variant={"outline"}
          />
        </div>
      </div>
      <div className="p-2 rounded-lg">
        <div className={"w-full lg:w-[550px] lg:m-auto"}>
          <Stepper
            activeStep={step}
            alternativeLabel
            nonLinear
            sx={{
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "grey.500", // Just text label (COMPLETED)
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: "#fb3310", // circle color (ACTIVE)
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
              {
                color: "common.white", // Just text label (ACTIVE)
              },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "white", // circle's number (ACTIVE)
              },
            }}
          >
            <Step key={0}>
              <StepLabel>General Information</StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>Address</StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>Social Media</StepLabel>
            </Step>
          </Stepper>
        </div>
        <div className="py-4 w-full lg:w-[550px] lg:m-auto">
          {step === 0 && <BasicForm fields={fields} />}
          {step === 1 && (
            <>
              <div className="py-6">
                <AutocompleteAddress
                  onSelected={(address) => setMerchantLocation(address)}
                />
              </div>
              <div className={"py-6"}>
                <Divider />
              </div>
              <div className="py-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                <Input
                  value={merchantLocation.addressline}
                  label="Business Address Line"
                  required
                />
                <Input value={merchantLocation.city} label="City" required />
                <Input
                  value={merchantLocation.county}
                  label="County"
                  required
                />
                <Input value={merchantLocation.state} label="State" required />
                <Input
                  value={merchantLocation.country}
                  label="Country"
                  required
                />
                <Input
                  value={merchantLocation.postal_code}
                  label="Postal Code"
                  required
                />
              </div>
              <div className="w-full p-4">
                <iframe
                  title="map"
                  className="w-full rounded-lg"
                  src={`https://maps.google.com/maps?q=${merchantLocation.coordinates.latitude},${merchantLocation.coordinates.longitude}&z=15&output=embed`}
                ></iframe>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <InfoBox
                message={
                  "Please input your social media usernames and facebook page name only, we'll take care of generating the links."
                }
              />
              <BasicForm fields={socialMediaFields} />
              <div className="flex items-center">
                <div className="flex-grow flex items-center">
                  <div
                    className={"flex-grow"}
                    onClick={() => setReviewOpen(true)}
                  >
                    <Input
                      label="Google Review Link"
                      value={merchantForm?.googleReviewUrl}
                    />
                  </div>
                  <div className={"pt-6 flex gap-2"}>
                    <Button
                      label="Generate"
                      onClick={() => setReviewOpen(true)}
                      icon={<KeyIcon />}
                      className="ml-2"
                    />
                    <Button
                      label="Clear"
                      onClick={() => setmerchantForm({ ...merchantForm, googleReviewUrl: "" })}
                      icon={<XCircleIcon />}
                      variant="outline"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-between mt-4 w-full lg:w-[550px] lg:m-auto">
          {(step === 1 || step === 2) && (
            <Button
              label="Back"
              buttonWidth={"full"}
              onClick={() => setStep(step - 1)}
              icon={<ArrowLeftIcon width={16} height={16} />}
              variant={"outline"}
            />
          )}
          {(step === 0 || step === 1) && (
            <Button
              label="Next"
              buttonWidth={"full"}
              onClick={() => setStep(step + 1)}
              icon={<ArrowRightIcon width={16} height={16} />}
              className="ml-auto"
            />
          )}
          {step === 2 && (
            <Button
              label="Create Merchant"
              buttonWidth={"full"}
              alwaysShowLabel
              onClick={createmerchant}
              icon={<PlusIcon width={16} height={16} />}
              className="ml-auto"
            />
          )}
        </div>
      </div>
    </div>
  );
}
