import { useMemo } from 'react';
import { useMetrics } from '../../hooks/metrics.hook';
import useAuthContext from '../../hooks/auth-context.hook';
import { MetricType, secondaryColors } from './type';
import { Card } from "../components/ui/card";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart
} from "recharts";

interface Props {
  metricType: MetricType;
  fromDate: Date;
  toDate: Date;
  primaryColor: string;
  couponId?: number;
  raffleId?: number;
}

export function MerchantMetricView({ primaryColor, metricType, couponId, raffleId, fromDate, toDate }: Props) {
  const auth = useAuthContext();
  const { merchant } = auth.getMerchantAndUser();

  const meta = () => {
    switch (metricType) {
      case ('STORE_FRONT_ARRIVED'): {
        return { title: 'Scanned into or arrived at store front', param: '' };
      }
      case ('LINK_CLICKED'): {
        return { title: 'Links clicked', param: '' };
      }
      case ('SOCIAL_SHARE'): {
        return { title: 'Social platforms users have shared to', param: '' };
      }
      case ('COUPON_CLAIMED'): {
        return { title: 'Qbon(s) rewarded to users count', param: 'couponMetricValue=CLAIMED' };
      }
      case ('COUPON_REDEEMED'): {
        return { title: 'Qbon(s) redeemed by users count', param: 'couponMetricValue=REDEEMED' };
      }
      case ('COUPON_CREATED'): {
        return { title: 'Qbons created count', param: 'couponMetricValue=CREATED' };
      }
      case ('COUPON_VIEWED'): {
        return { title: 'View count for Qbon', param: 'couponMetricValue=VIEWED' };
      }
      case ('RAFFLE_ENTERED'): {
        return { title: 'Users entering raffle count', param: 'raffleMetricValue=ENTERED' };
      }
      case ('CONTENT_POSTED'): {
        return { title: 'Count of content posted to your feed', param: 'contentMetricValue=CREATED' };
      }
      case ('MERCHANT_CREATED'): {
        return { title: 'Merchants created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      case ('USER_CREATED'): {
        return { title: 'Users created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      default: {
        return { title: 'No metric available', param: '' };
      }
    }
  };

  const couponIdQueryParam = couponId ? `couponId=${couponId}` : '';
  const raffleIdQueryParam = raffleId ? `raffleId=${raffleId}` : '';

  const { useMerchantMetricsQuery } = useMetrics();
  const { data, isSuccess, isLoading } = useMerchantMetricsQuery(
    merchant.id,
    metricType,
    fromDate,
    toDate,
    [meta().param, couponIdQueryParam, raffleIdQueryParam],
  );

  function extractUniqueKeys(obj: any): string[] {
    const uniqueKeys = new Set<string>();

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && key.toLowerCase() !== 'date') {
        uniqueKeys.add(key);
      }
    }

    return Array.from(uniqueKeys);
  }

  const areas = useMemo(() => {
    if (isSuccess) {
      const keys = extractUniqueKeys(data.timeseries[0]);
      return (
        keys.map((key, index) => {
          const color = index === 0 ? primaryColor : secondaryColors[index % 10];
          const gradientId = `colorGradient-${key}`;
          
          return (
            <>
              <defs key={`defs-${key}`}>
                <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={color} stopOpacity={0.3}/>
                  <stop offset="95%" stopColor={color} stopOpacity={0}/>
                </linearGradient>
              </defs>
              <Area
                key={key}
                type="monotone"
                dataKey={key}
                stroke={color}
                strokeWidth={2}
                fill={`url(#${gradientId})`}
                dot={false}
                activeDot={{ 
                  r: 6, 
                  stroke: 'white',
                  strokeWidth: 2,
                  fill: color
                }}
              />
            </>
          );
        })
      );
    }
    return null;
  }, [data, isSuccess, primaryColor]);

  if (metricType === 'COUPON_VIEWED' && !couponId) {
    return (<>Can not display this metric</>);
  }

  if (metricType === 'RAFFLE_ENTERED' && !raffleId) {
    return (<>Can not display this metric</>);
  }

  return (
    <>
      <div className="font-bold mb-4">{meta().title}</div>
      {!isLoading && (
        <Card className="p-4">
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={isSuccess ? data.timeseries : []}
              margin={{
                top: 10,
                right: 10,
                bottom: 20,
                left: 10,
              }}
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
                vertical={false}
                stroke="#f0f0f0"
              />
              <XAxis 
                dataKey="date" 
                tick={{ fontSize: 12, fill: '#666' }}
                tickLine={false}
                axisLine={false}
              />
              <YAxis 
                tick={{ fontSize: 12, fill: '#666' }}
                tickLine={false}
                axisLine={false}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: 'white',
                  border: '1px solid #f0f0f0',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
              />
              {areas}
            </AreaChart>
          </ResponsiveContainer>
        </Card>
      )}
    </>
  );
}