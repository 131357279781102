import { useFetch } from './fetch.hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MerchantUserInvite } from '../models/invites';

const Routes = {
  merchantInvites: (merchantId: number) => `/merchants/${merchantId}/invites?statusId=1`,
};

export default function useMerchantInvites() {
  const { fetchJsonFunc } = useFetch();

  const useInviteUserToMerchantMutation = (
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    return useMutation({
      mutationFn: ({ merchantId, email }: {
        merchantId: number,
        email: string
      }) => fetchJsonFunc<MerchantUserInvite>(Routes.merchantInvites(merchantId), {
        method: 'POST',
        body: JSON.stringify({ email }),
      }),
      onSuccess,
      onError,
    });
  };

  const useGetMerchantInvitesQuery = (
    merchantId: number,
  ) => {
    return useQuery({
      queryKey: ['merchantInvites', merchantId],
      queryFn: () => fetchJsonFunc<MerchantUserInvite[]>(Routes.merchantInvites(merchantId)),
    });
  };

  const useDeleteMerchantInviteMutation = (
    merchantId: number,
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    return useMutation({
      mutationFn: (inviteId: number) => fetchJsonFunc(`/merchants/${merchantId}/invites/${inviteId}`, {
        method: 'DELETE',
      }),
      onSuccess,
      onError,
    });
  };

  return {
    useGetMerchantInvitesQuery,
    useInviteUserToMerchantMutation,
    useDeleteMerchantInviteMutation,
  };
}
