import { useState } from "react";
import { Coupon, CouponStatus } from "../../../models/coupon";
import Button from "../../../common/library/Button";
import { PlusIcon } from "@heroicons/react/solid";
import { PageInfo } from "../../../common/library/Pagination";
import { useNavigate } from "react-router";
import useCoupons from "../../../hooks/coupon.hook";
import useAuthContext from "../../../hooks/auth-context.hook";
import Table, { TableColumn } from "../../../common/library/Table";
import { expirationDateToText } from "../../../util";
import { Card } from "../../../common/components/ui/card";
import { ShareIcon } from "@heroicons/react/outline";

export default function MerchantCoupons() {
  const { merchant } = useAuthContext().getMerchantAndUser();
  const navigate = useNavigate();

  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 0,
    pageSize: 10,
  });

  const [filter] = useState<CouponStatus>("AVAILABLE");
  const { useMerchantCouponsQuery } = useCoupons();

  const { data: referralCoupon, isSuccess: isReferralSuccess } =
    useMerchantCouponsQuery(
      merchant.id,
      "AVAILABLE",
      0,
      10,
      undefined,
      "REFERRAL"
    );


  const {
    data: coupons,
    isSuccess,
    isLoading: isLoadingCoupons,
  } = useMerchantCouponsQuery(
    merchant.id,
    filter,
    pageInfo.pageNumber,
    pageInfo.pageSize,
    {
      pageNumber: 0,
      pageSize: 0,
      totalSize: 0,
      hasNext: false,
      hasPrevious: false,
      data: [
        ...(filter === "AVAILABLE"
          ? [
              {
                id: -1,
                name: "10% Off Your Next Purchase",
                description:
                  "This is a sample coupon, to create one click on the plus icon above!",
                status: "SAMPLE",
                startDate: new Date(),
                endDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
                merchantLocationId: 0,
                type: "PRIMARY",
                code: undefined,
                couponImageUrl: undefined,
                createdDate: new Date(),
                updatedDate: new Date(),
              } as any,
            ]
          : []),
      ],
    }
  );

  const columns: TableColumn<Coupon>[] = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Description", 
      key: "description",
      className: "hidden md:table-cell",
    },
    {
      label: "Type",
      format: (coupon: Coupon) => {
        return coupon.type.charAt(0) + coupon.type.slice(1).toLowerCase();
      },
    },
    {
      label: "Status",
      format: (coupon: Coupon) => {
        return (
          <div className="text-center">
            {coupon.status === "SAMPLE" ? (
              <div className="text-yellow-500">Sample Coupon</div>
            ) : coupon.startDate && new Date(coupon.startDate) > new Date() ? (
              <div className="text-yellow-500">Starts in Future</div>
            ) : (
              <div className="text-green-500">Actively Distributing</div>
            )}
          </div>
        );
      },
    },
    {
      label: "Expiration Date",
      className: "hidden md:table-cell",
      format: (coupon: Coupon) => {
        return expirationDateToText(
          coupon.startDate && new Date(coupon.startDate),
          coupon.endDate && new Date(coupon.endDate)
        );
      },
    },
    {
      label: "Created Date",
      className: "hidden md:table-cell",
      format: (coupon: Coupon) => {
        return new Date(coupon.createdDate).toLocaleDateString();
      },
    },
  ];

  return (
    <>
      <h1 className="font-bold text-xl px-2 md:px-0 pb-2">Qbons</h1>
      <div className="pb-6">
        <Card className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <ShareIcon className="h-6 w-6 text-primary" />
            <h2 className="font-bold text-lg">Referral Qbon</h2>
          </div>

          {isReferralSuccess && referralCoupon?.data.length === 0 ? (
            <div className="space-y-4">
              <p className="text-gray-600 text-sm">
                No Referral Qbon is active! A Referral Qbon is a discount your
                customers can share with their friends via a link when they
                post. Incentivize your customers' friends to visit your
                business.
              </p>
              <Button
                label="Create Referral Qbon"
                buttonWidth="full"
                variant="outline"
                onClick={() =>
                  navigate(`/dashboard/qbons/create?type=REFERRAL`)
                }
              />
            </div>
          ) : (
            isReferralSuccess &&
            referralCoupon?.data.length > 0 && (
              <div
                className="p-4 rounded-lg border border-primary/20 hover:bg-primary/5 transition-colors cursor-pointer"
                onClick={() =>
                  navigate(`/dashboard/qbons/${referralCoupon?.data[0].id}`)
                }
              >
                <h3 className="font-semibold mb-2">
                  {referralCoupon?.data[0].name}
                </h3>
                <p className="text-gray-600 text-sm">
                  {referralCoupon?.data[0].description}
                </p>
              </div>
            )
          )}
        </Card>
      </div>

      <div className={"flex justify-between w-full"}>
        <div></div>
        <div className="pb-2">
          <Button
            onClick={() => navigate(`/dashboard/qbons/create?type=SHARE`)}
            icon={<PlusIcon height={16} width={16} />}
            label="Create Qbon"
          />
        </div>
      </div>
      <Table
        loading={isLoadingCoupons}
        columns={columns}
        onRowClick={(coupon) => navigate(`/dashboard/qbons/${coupon.id}`)}
        data={coupons ? coupons.data : []}
        pagination={{
          pageInfo: {
            pageNumber: isSuccess
              ? coupons.pageNumber + 1
              : pageInfo.pageNumber,
            pageSize: isSuccess ? coupons.pageSize : pageInfo.pageSize,
            totalSize: isSuccess ? coupons.totalSize : 0,
            hasPrevious: isSuccess ? coupons.hasPrevious : false,
            hasNext: isSuccess ? coupons.hasNext : false,
          },
          onChange: (info: PageInfo) => {
            setPageInfo({
              pageNumber: info.pageNumber - 1,
              pageSize: info.pageSize,
            });
          },
        }}
      />
    </>
  );
}
