import { useSpotlightItemsQuery } from '../../../hooks/spotlight.hook';
import Table, { TableColumn } from '../../../common/library/Table';
import useAuthContext from '../../../hooks/auth-context.hook';
import Button from '../../../common/library/Button';
import { SpotlightItem } from '../../../models/spotlight';
import { useNavigate } from 'react-router-dom';

export default function SpotlightListPage() {
  const { merchant } = useAuthContext().getMerchantAndUser();
  const { data: spotlightItems, isLoading } = useSpotlightItemsQuery(merchant.id);
  const navigate = useNavigate();

  const columns: TableColumn<SpotlightItem>[] = [
    {
      label: 'Title', 
      format: (item: SpotlightItem) => {
        if (!item.url) return <span>-</span>;
        return <span>{item.title.length > 40 ? `${item.title.substring(0, 40)}...` : item.title}</span>;
      }
    },
    {
      label: 'URL',
      format: (item: SpotlightItem) => {
        if (!item.url) return <span>-</span>;
        return <span>{item.url.length > 40 ? `${item.url.substring(0, 40)}...` : item.url}</span>;
      }
    },
    {
      label: 'Image URL',
      format: (item: SpotlightItem) => {
        if (!item.imageUrl) return <span>-</span>;
        return <span>{item.imageUrl.length > 40 ? `${item.imageUrl.substring(0, 40)}...` : item.imageUrl}</span>;
      }
    },
  ];

  return (
    <div className="w-full">
      <div className="flex justify-between px-2 md:px-0 w-full pb-2">
        <h1 className="font-bold text-xl">Spotlight Items</h1>
        <div className="float-right">
          <Button
            label="Create Spotlight Item"
            onClick={() => navigate('/dashboard/spotlight/create')}
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={spotlightItems || []}
        loading={isLoading}
        onRowClick={(item) => navigate(`/dashboard/spotlight/${item.id}`)}
      />
    </div>
  );
} 