import { useNavigate, useParams } from "react-router-dom";
import { useMerchantDeviceQuery, useUpdateMerchantDeviceMutation } from "../../../hooks/devices.hook";
import { DeviceCustomLink, QbonDevice } from "../../../models/devices";
import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { Button } from "../../../common/library/Button";
import { PlusIcon, TrashIcon, ChevronRightIcon, XIcon, SaveIcon, UploadIcon } from "@heroicons/react/solid";
import useAuthContext from "../../../hooks/auth-context.hook";
import Card from "../../../common/library/Card";
import { CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../common/components/ui/card";
import { Label } from "../../../common/components/ui/label";
import { Input } from "../../../common/components/ui/input";
import Spinner from "../../../common/library/Spinner";
import useContent from "../../../hooks/content.hook";

export default function MerchantSingleDevicePage() {
    const navigate = useNavigate();
    const { deviceId } = useParams();
    const { getMerchantAndUser } = useAuthContext();
    const { merchant } = getMerchantAndUser();
    const { useUploadMerchantMediaMutation } = useContent();
    const { mutate: uploadFile, isPending: isUploading } = useUploadMerchantMediaMutation(
        merchant.id,
        (response) => {
            setNewLink({
                ...newLink,
                url: response.url,
                label: newLink.label
            });
        },
        (error) => {
            toast.error("Failed to upload file");
            console.error(error);
        }
    );

    const { data: device, refetch, error, isLoading } = useMerchantDeviceQuery(deviceId!);
    const { mutate: updateDevice, isPending} = useUpdateMerchantDeviceMutation(
        () => {
            toast.success("Device updated successfully");
            refetch();
        },
        () => {
            toast.error("Failed to update device");
            console.error(error);
        }
    );

    const [deviceName, setDeviceName] = useState<string>("");
    const [customLinks, setCustomLinks] = useState<(DeviceCustomLink & { id: string })[]>([]);
    const [showAddLinkForm, setShowAddLinkForm] = useState<boolean>(false);
    const [newLink, setNewLink] = useState<{ label: string; url: string }>({ label: "", url: "" });
    const [isDragging, setIsDragging] = useState<boolean>(false);

    useEffect(() => {
        if (device) {
            setDeviceName(device.nickName || "");
            setCustomLinks(device.customLinks.map((link, index) => ({ ...link, id: index.toString() })));
        }
    }, [device]);

    const handleUpdateDevice = () => {
        if (!device || !merchant) return;

        const updatedDevice: QbonDevice = {
            ...device,
            nickName: deviceName,
            customLinks: customLinks
        };

        updateDevice(
            { ...updatedDevice, merchantId: merchant.id }
        );
    };

    const addCustomLink = () => {
        setShowAddLinkForm(true);
    };

    const handleAddLink = () => {
        if (!newLink.label || !newLink.url) {
            toast.error("Please provide both label and URL");
            return;
        }

        const newId = Date.now().toString();
        const linkToAdd = {
            id: newId,
            label: newLink.label,
            url: newLink.url
        };

        setCustomLinks([...customLinks, linkToAdd]);
        setNewLink({ label: "", url: "" });
        setShowAddLinkForm(false);
    };

    const cancelAddLink = () => {
        setNewLink({ label: "", url: "" });
        setShowAddLinkForm(false);
    };

    const updateCustomLink = (id: string, field: "url" | "label", value: string) => {
        setCustomLinks(
            customLinks.map(link =>
                link.id === id ? { ...link, [field]: value } : link
            )
        );
    };

    const removeCustomLink = (id: string) => {
        setCustomLinks(customLinks.filter(link => link.id !== id));
    };

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback(async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
        
        const files = e.dataTransfer.files;
        if (files.length === 0) return;
        
        const file = files[0];
        try {
            await uploadFile(file);
        } catch (error) {
            toast.error("Failed to upload file");
            console.error(error);
        }
    }, [uploadFile]);

    const handleFileSelect = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files || files.length === 0) return;
        
        const file = files[0];
        try {
            await uploadFile(file);
        } catch (error) {
            toast.error("Failed to upload file");
            console.error(error);
        }
        
        // Reset the input value so the same file can be selected again
        e.target.value = '';
    }, [uploadFile]);

    useEffect(() => {
        if (error) {
            toast.error("Error loading device");
            navigate('/dashboard/devices');
        }
    }, [error, navigate]);

    if (isLoading) {
        return (
            <div className="p-3 md:p-0">
                <div className="flex flex-col px-2 md:px-0 w-full pb-2">
                    <h1 className="font-bold text-xl mb-4">Device</h1>
                    <Card>
                        <CardContent className="pt-6">
                            <div className="flex items-center justify-center h-40">
                                <div className="animate-pulse">Loading device information...</div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }

    return (
        <div className={"p-3 md:p-0"}>
            <div className="flex flex-col px-2 md:px-0 w-full pb-2">
                <div className="flex flex-row">
                    <div>
                        <h1
                            className="font-bold text-xl pb-4 px-2 md:px-0 underline cursor-pointer text-[#fb3310]"
                            onClick={() => navigate('/dashboard/devices')}
                        >
                            Devices
                        </h1>
                    </div>
                    <div>
                        <ChevronRightIcon
                            className="h-6 w-6 text-gray-500 pt-1"
                            aria-hidden="true"
                        />
                    </div>
                    <div>
                        <h1 className="font-bold text-xl pb-4 px-2 md:px-0">
                            {device?.uuid}
                        </h1>
                    </div>
                </div>

                <Card className="mb-6">
                    <CardHeader>
                        <CardTitle>Device Information</CardTitle>
                        <CardDescription>
                            ID: {device?.uuid}
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="deviceName">Device Name</Label>
                            <Input
                                id="deviceName"
                                value={deviceName}
                                onChange={(e: any) => setDeviceName(e.target.value)}
                                placeholder="Enter device name"
                            />
                        </div>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle>Device Custom Links</CardTitle>
                        <CardDescription>
                            Add custom links that will be accessible from this device
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        {customLinks.length === 0 && !showAddLinkForm ? (
                            <div className="text-center py-4 text-gray-500">
                                No custom links added yet
                            </div>
                        ) : (
                            customLinks.map((link) => (
                                <div key={link.id} className="flex flex-col md:flex-row gap-3">
                                    <div className="flex-1">
                                        <Label htmlFor={`label-${link.id}`}>Label</Label>
                                        <Input
                                            id={`label-${link.id}`}
                                            value={link.label}
                                            onChange={(e) => updateCustomLink(link.id, "label", e.target.value)}
                                            placeholder="Link Label"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <Label htmlFor={`url-${link.id}`}>URL</Label>
                                        <Input
                                            id={`url-${link.id}`}
                                            value={link.url}
                                            onChange={(e) => updateCustomLink(link.id, "url", e.target.value)}
                                            placeholder="https://example.com"
                                        />
                                    </div>
                                    <div className="flex items-end">
                                        <Button
                                            variant="danger"
                                            size="icon"
                                            className="w-[50px]"
                                            onClick={() => removeCustomLink(link.id)}
                                            icon={<TrashIcon className="h-4 w-4" />}
                                        />
                                    </div>
                                </div>
                            ))
                        )}

                        {showAddLinkForm && (
                            <div className="border p-4 rounded-md bg-gray-50">
                                <div className="flex justify-between items-center mb-3">
                                    <h3 className="font-medium">Add New Link</h3>
                                    <Button
                                        size="icon"
                                        onClick={cancelAddLink}
                                    >
                                        <XIcon className="h-4 w-4" />
                                    </Button>
                                </div>
                                <div className="flex flex-col md:flex-row gap-3">
                                    <div className="flex-1">
                                        <Label htmlFor="new-label">Label</Label>
                                        <Input
                                            id="new-label"
                                            value={newLink.label}
                                            onChange={(e) => setNewLink({...newLink, label: e.target.value})}
                                            placeholder="Link Label"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <Label htmlFor="new-url">URL</Label>
                                        <Input
                                            id="new-url"
                                            value={newLink.url}
                                            onChange={(e) => setNewLink({...newLink, url: e.target.value})}
                                            placeholder="https://example.com"
                                        />
                                    </div>
                                </div>
                                
                                <div 
                                    className={`mt-4 border-2 border-dashed rounded-md p-6 text-center cursor-pointer transition-colors ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}`}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                    onClick={() => document.getElementById('file-upload')?.click()}
                                >
                                    <input
                                        id="file-upload"
                                        type="file"
                                        className="hidden"
                                        onChange={handleFileSelect}
                                    />
                                    <UploadIcon className="h-8 w-8 mx-auto text-gray-400" />
                                    <p className="mt-2 text-sm text-gray-600">
                                        {isUploading ? (
                                            <span className="flex items-center justify-center">
                                                <Spinner width={16} height={16} color="blue" />
                                                <span className="ml-2">Uploading file...</span>
                                            </span>
                                        ) : (
                                            <span>
                                                Drag and drop a file here, or click to select a file
                                                <br />
                                                <span className="text-xs text-gray-500">
                                                    The file will be uploaded and its URL will be used for the link
                                                </span>
                                            </span>
                                        )}
                                    </p>
                                </div>
                                
                                <div className="flex justify-end mt-4 gap-2">
                                    <Button
                                        variant="outline"
                                        onClick={cancelAddLink}
                                        label="Cancel"
                                    />
                                    <Button
                                        onClick={handleAddLink}
                                        label="Add Link"
                                        disabled={isUploading}
                                    />
                                </div>
                            </div>
                        )}

                        {!showAddLinkForm && (
                            <Button
                                variant="outline"
                                onClick={addCustomLink}
                                className="w-full"
                                icon={<PlusIcon className="h-4 w-4 mr-2" />}
                                label="Add Custom Link"
                            />
                        )}
                    </CardContent>
                    <CardFooter className="flex justify-end">
                        <Button
                            onClick={handleUpdateDevice}
                            disabled={isPending || isUploading}
                            label={isPending ? "Saving..." : "Save Changes"}
                            icon={isPending ? <Spinner width={16} height={16} color="red" /> : <SaveIcon className="h-4 w-4" />}
                        />
                    </CardFooter>
                </Card>
            </div>
        </div>
    );
}
