import AutocompleteAddress from '../../../common/AutocompleteAddress';
import Input from '../../../common/library/Input';
import Button from '../../../common/library/Button';
import { PlusIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { GeoLocation } from '../../../hooks/google-maps.hook';
import { Modal } from '../../../common/library/Modal';
import { BeakerIcon } from '@heroicons/react/solid';

export interface Props {
  open: boolean;
  onFinished: (reviewLink: string) => void;
  onClose: () => void;
}

export default function GenerateGoogleReviewLink({ open, onFinished, onClose }: Props) {
  const [link, setLink] = useState<string>('');
  const [address, setAddress] = useState<GeoLocation>();

  return (
    <>
      <Modal open={open} onClose={onClose} buttonLabel='Close'>
        <div className="py-3">
          <div className="py-3">
            <h1 className="font-bold ">Update Google Review Link</h1>
          </div>
          <div className="py-3">
            <AutocompleteAddress
              label={'Search by business name'}
              onSelected={(address) => {
                console.log(address);
                const googleReviewUrl = `https://search.google.com/local/writereview?placeid=${address.placeId}`;
                setLink(googleReviewUrl);
                setAddress(address);
              }}
            />
          </div>
          <div className="w-full p-1.5">
            {address && <iframe
              title="map"
              className="w-full"
              src={`https://maps.google.com/maps?q=${address.coordinates.latitude},${address.coordinates.longitude}&z=15&output=embed`}
            ></iframe>}
          </div>
          <div className="py-3">
            <Input label={'Google Review Link'} value={link} />
          </div>
          <div className="py-3">
            <Button
              label="Click to test link"
              icon={<BeakerIcon />}
              variant="outline"
              buttonWidth="full"
              alwaysShowLabel
              onClick={() => {
                window.open(link, '_blank', 'noopener,noreferrer');
              }}
            />
          </div>
          <div className="py-3">
            <Button
              label="Set Google Review Link"
              icon={<PlusIcon />}
              variant="danger"
              alwaysShowLabel
              buttonWidth="full"
              onClick={() => {
                onFinished(link);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}